<template>
  <div
    id="proof-of-profit"
    class="section py-10 lg:py-12 relative bg-primary-700"
  >
    <div class="container relative z-2">
      <h2
        class="text-7.5 lg:text-10 font-sf-pro text-secondary-600 font-bold leading-normal text-center mb-3 lg:mb-0"
      >
        {{ data?.title }}
      </h2>
      <div
        v-html="data?.description"
        class="text-white text-base !leading-[1.5] lg:!leading-[2.75] text-center mb-3"
      ></div>
      <Swiper
        :modules="[SwiperNavigation, SwiperEffectCoverflow]"
        :centered-slides="true"
        :grabCursor="true"
        :navigation="{
          nextEl: '.next-swiper-profit',
          prevEl: '.prev-swiper-profit',
        }"
        :loop="true"
        :coverflowEffect="{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }"
        :effect="'coverflow'"
        :breakpoints="{
          0: { slidesPerView: 1.5, spaceBetween: 12 },
          768: { slidesPerView: 1.8, spaceBetween: 40 },
          1200: { slidesPerView: 2.2, spaceBetween: 96 },
        }"
        class="custom-swiper swiper-profit"
      >
        <SwiperSlide
          v-for="item in data?.images"
          :key="item?.id"
          class="!h-auto"
        >
          <div class="flex flex-col gap-6.5 items-center">
            <div class="w-max rounded-1 title opacity-0">
              <h3 class="p-2 text-base lg:text-xl font-bold text-gradient-3">
                {{ item?.caption_title }}
              </h3>
            </div>

            <div class="relative">
              <app-image
                :id="item?.avatar"
                class="w-full h-full object-cover aspect-414/420"
                width="414"
                height="420"
                :alt="`${item?.name} - ${item?.content}`"
              />
              <div
                class="background-gradient-prev bg-gradient-3 absolute inset-0 z-10 hidden"
              ></div>

              <div
                class="background-gradient-next bg-gradient-4 absolute inset-0 z-10 hidden"
              ></div>
            </div>
            <div
              class="absolute w-full top-52% transform z-10 hidden md:flex justify-between"
            >
              <div
                class="prev-swiper-profit pointer-events-none -translate-x-18 cursor-pointer transition-all duration-200 ease group hover:scale-115 opacity-80 hover:opacity-100 hidden"
              >
                <icons-arrow-right-light
                  class="transform rotate-180 text-secondary-50 w-9 h-9"
                />
              </div>
              <div
                class="next-swiper-profit pointer-events-none transform translate-x-18 cursor-pointer transition-all duration-200 ease group hover:scale-115 opacity-80 hover:opacity-100 hidden"
              >
                <icons-arrow-right-light
                  class="transform text-secondary-50 w-9 h-9"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="hidden sm:flex justify-center mt-6 lg:mt-7.5">
        <app-button
          :title="t('GET_STARTED')"
          :link="linkButton"
          target="_blank"
          class=""
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  data: Object,
  linkButton: String,
});

const { width } = useWindowSize();

const centeredSlide = ref(true);
onMounted(() => {
  centeredSlide.value = width.value > 768;
});
watch(
  () => width.value,
  () => {
    centeredSlide.value = width.value > 768;
  }
);

const { t } = useI18n();
</script>
<style>
.swiper-profit .swiper-slide-active .title,
.swiper-profit .swiper-slide-active {
  opacity: 100%;
}

.swiper-profit .swiper-slide-shadow-right,
.swiper-profit .swiper-slide-shadow-left {
  display: none !important;
  background-image: none !important;
}

.swiper-profit .swiper-slide-prev,
.swiper-profit .swiper-slide-next {
  opacity: 0.8;
  transform: scale(0.8) !important;
}

.swiper-profit .swiper-slide-next .background-gradient-next {
  display: block;
}

.swiper-profit .swiper-slide-prev .background-gradient-prev {
  display: block;
}

.swiper-profit .swiper-slide-next {
  transform-origin: left center !important;
}

.swiper-profit .swiper-slide-prev {
  transform-origin: right center !important;
}

.swiper-profit .swiper-slide-active .next-swiper-profit,
.swiper-profit .swiper-slide-active .prev-swiper-profit {
  pointer-events: all;
  display: block !important;
}
</style>
