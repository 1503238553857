import revive_payload_client_tfLlGUoi0q from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zSCahMcSLs from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0SsPlKSuPH from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_oZab0UGIoc from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.9/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import debug_zOa8B9eX2Q from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_NVYiE4afuw from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OTH9i4taMK from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gBtl0f7iDF from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pwgf4EsdYF from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oWU5eyQNgQ from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.24.0_typescript@5.3.3_vue@3.5.9/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_i7jIpWjSX2 from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.0.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pn8LgXjU8I from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_rollup@4.24.0_vue@3.5.9/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_OJu8Ze2q0g from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.24.0_vue@3.5.9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/.nuxt/unocss.mjs";
import plugin_Ls0HFo39vR from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/base/modules/hooks/runtime/plugin.ts";
import plugin_TFKfrRFnRm from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/directus/modules/nad/runtime/plugin.ts";
import _01_global_config_LKMxy5uGDE from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/directus/plugins/01.global.config.ts";
import _02_directus_instance_8QbszPjc51 from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/directus/plugins/02.directus.instance.ts";
import config_gkDjCp4obN from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/layers/base/plugins/config.ts";
import _01_global_g15XVlEHyj from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/plugins/01.global.ts";
import _02_update_i18n_translate_global_CpAYjKMTFa from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/plugins/02.update.i18n.translate.global.ts";
import country_YYdDEJuhVN from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/plugins/country.ts";
import router_WMnTGednOQ from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/plugins/router.ts";
import sentry_client_shVUlIjFLk from "/builds/outsourcing/projects/skymoon/ver-2-landingpage-nuxt/plugins/sentry.client.ts";
export default [
  revive_payload_client_tfLlGUoi0q,
  unhead_zSCahMcSLs,
  router_0SsPlKSuPH,
  _0_siteConfig_oZab0UGIoc,
  debug_zOa8B9eX2Q,
  payload_client_NVYiE4afuw,
  navigation_repaint_client_OTH9i4taMK,
  check_outdated_build_client_gBtl0f7iDF,
  chunk_reload_client_pwgf4EsdYF,
  plugin_vue3_oWU5eyQNgQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_i7jIpWjSX2,
  plugin_pn8LgXjU8I,
  i18n_OJu8Ze2q0g,
  unocss_MzCDxu9LMj,
  plugin_Ls0HFo39vR,
  plugin_TFKfrRFnRm,
  _01_global_config_LKMxy5uGDE,
  _02_directus_instance_8QbszPjc51,
  config_gkDjCp4obN,
  _01_global_g15XVlEHyj,
  _02_update_i18n_translate_global_CpAYjKMTFa,
  country_YYdDEJuhVN,
  router_WMnTGednOQ,
  sentry_client_shVUlIjFLk
]