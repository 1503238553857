import {customEndpoint} from "@directus/sdk";
import { register } from 'swiper/element/bundle';



export default defineNuxtPlugin(async (nuxtApp) => {
    const countryUser = useLocalStorage("countryUser");
    const country_config = useState("country_config")
    const country_current = useState("country_current")

    const country = ref()
    const responseGeoip = ref()
    const ipUser = await useFetch("/api/ip");

    let {
        $adminClient,
    }: any = useNuxtApp()

    country_config.value = await $adminClient.request(customEndpoint({
        method: "GET",
        path: "country"
    }))

    if(countryUser.value && countryUser.value !== "undefined"){
        if (JSON.parse(countryUser.value)?.ipUser !== ipUser.data.value) {
            responseGeoip.value = await $adminClient.request(
                customEndpoint({
                    path: "/geoip",
                    method: "GET",
                })
            );
            countryUser.value = JSON.stringify(responseGeoip.value);
            country.value = responseGeoip.value?.country?.code;
        }
    }
    else {
        responseGeoip.value = await $adminClient.request(
            customEndpoint({
                path: "/geoip",
                method: "GET",
            })
        );
        countryUser.value = JSON.stringify(responseGeoip.value);
        country.value = responseGeoip.value?.country?.code;
    }

    country_current.value =  country_config.value?.find((item: any) => item?.value == country.value)
   
    return {};
})
